.modal-content {
    width: auto
}

.fix_wd {
    width: 150px;
    margin-left: 10px;
}

.modal-title {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding:5px
}
.mx-5{
    margin: 0 5px;
}
.my-5{
    margin: 5px 0;
}
.mx-10{
    margin: 0 10px;
}
.my-10{
    margin: 10px 0;
}
.mx-20{
    margin: 0 20px;
}
.my-20{
    margin: 20px 0;
}
.px-5{
    padding: 0 5px;
}
.py-5{
    padding: 5px 0;
}
.px-10{
    padding: 0 10px;
}
.py-10{
    padding: 10px 0;
}
.px-20{
    padding: 0 20px;
}
.py-20{
    padding: 20px 0;
}

.ml-5 {
    margin-left: 5px;
}
.pac-container{
    z-index:9999999
}

.item_review_action {
    margin: 0;
    padding-right: 0;
}

.items_page .list_inner {
    min-height: 120px;
}
.map-marker {
    position: absolute;
    top: 44%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
}
.map_address {
    display: flex; 
    align-items: center; 
    padding: 10px
}
.suggestion-list {
    background: #fff;
    position: absolute;
    left: 0;
    right: 0;
    border-radius: 0 0 15px 15px;   
} 

.suggestion-list * {
    background: transparent;
}

.suggestion-list a {
    padding: 5px 15px;
    border-bottom: 1px solid #eee;
    width:100%;
    display:inline-block;
    font-size:13px;
    color:#333;
}
  

.map-overlay {
    position: absolute!important;
    z-index: 3;
    left:0;
    right: 0;
    padding: 8px 0px;
    position: relative;
    max-width:calc(100% - 236px);
    margin: 3px 36px 0 200px;
}

.map-overlay input {
    border: 0px;
    border-radius: 8px;
}

.map-search {
    padding-left: 5px; 
    display: flex; 
    align-items: center; 
    background: #FFF; 
    border-radius: 8px;
}

.small-grey-icon {
    color: grey;
    margin: 10px;
}