 

.table-style2 .drawerHead {
    width: 0;
    transition: all .3s;
    -weki-transition: all .3s;
    float: right;
    margin: 0 10px;
    /* transform: translateX(110%); */
    background: #eee;
    max-height: 400px;
    overflow-y: auto;
}

.table-style2 .drawerHead .MuiContainer-root {
    padding: 0;
}

.table-style2 .drawerHead .MuiList-root.MuiList-padding {
    max-width: 100%;
    border: 1px solid #ddd;
    background: transparent;
}

.table-style2 .drawerHead .MuiList-root.MuiList-padding {
    max-width: 100%;
    background: #ffffff;
    padding: 10px 20px;
}

.table-style2 .table-content {
    float: left;
    width: 100%;
    transition: all .3s;
    -webkit-transition: all .3s;
}

 

.smallTable  .table-content {
    width: calc(100% - 500px);
}

 

.table-style2 .ViewDrawer {
    transform: translateX(0%);
    width: 450px;
}
.smallTable .table-content .MuiTableContainer-root {
    min-width: 100%;
}


.table-style2 .css-qerafn-MuiTablePagination-root {
    float: right;   
    width:100%;
}
.smallTable  .css-qerafn-MuiTablePagination-root {
    float: left!important;
    width: calc(100% - 500px);
}
  

.draw-title {background: #083dfa;padding: 15px;color: #fff;}

.draw-title h4 {
    margin: 0;
}