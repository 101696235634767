/* fonts */

/* sf-pro */

/* bold */

@font-face {
  font-family: 'SFProText';
  src: url(/static/media/SFProText-Semibold.93930ed2.woff2) format('woff2'),
    url(/static/media/SFProText-Semibold.176d1473.woff) format('woff');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

/* bold */

/* regular */
@font-face {
  font-family: 'SFProText';
  src: url(/static/media/SFProText-Regular.b2a6f578.woff2) format('woff2'),
    url(/static/media/SFProText-Regular.a44e9e98.woff) format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

/* regular */

/* sf-pro */

/* heavy-font */

@font-face {
  font-family: 'Avenir';
  src: url(/static/media/Avenir-Heavy.1bed8278.eot);
  src: url(/static/media/Avenir-Heavy.1bed8278.eot?#iefix) format('embedded-opentype'),
    url(/static/media/Avenir-Heavy.e5cb2005.woff2) format('woff2'),
    url(/static/media/Avenir-Heavy.fdf89dab.woff) format('woff'),
    url(/static/media/Avenir-Heavy.2b5105eb.svg#Avenir-Heavy) format('svg');
  font-weight: 850;
  font-style: normal;
  font-stretch: normal;
  unicode-range: U+0020-00FE;
}

/* heavy-font */

/* light-font */

@font-face {
  font-family: 'Avenir';
  src: url(/static/media/Avenir-Light.f98f1db7.eot);
  src: url(/static/media/Avenir-Light.f98f1db7.eot?#iefix) format('embedded-opentype'),
    url(/static/media/Avenir-Light.20e94af7.woff2) format('woff2'),
    url(/static/media/Avenir-Light.33dab461.woff) format('woff'),
    url(/static/media/Avenir-Light.c90c479d.svg#Avenir-Light) format('svg');
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  unicode-range: U+0020-00FE;
}

/* light-font */

/* medium-font */

@font-face {
  font-family: 'Avenir';
  src: url(/static/media/Avenir-Medium.0d479627.eot);
  src: url(/static/media/Avenir-Medium.0d479627.eot?#iefix) format('embedded-opentype'),
    url(/static/media/Avenir-Medium.d5ed879b.woff2) format('woff2'),
    url(/static/media/Avenir-Medium.764eca7c.woff) format('woff'),
    url(/static/media/Avenir-Medium.062ca8a0.svg#Avenir-Medium) format('svg');
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  unicode-range: U+0020-00FE;
}

/* medium-font */

/* roman-font */

@font-face {
  font-family: 'Avenir';
  src: url(/static/media/n.0f3eee20.eot);
  src: url(/static/media/n.0f3eee20.eot?#iefix) format('embedded-opentype'),
    url(/static/media/n.7f3772e1.woff2) format('woff2'), url(/static/media/n.44b54e01.woff) format('woff'),
    url(/static/media/n.e5174235.svg#n) format('svg');
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
  unicode-range: U+0020-E305;
}

/* roman-font */

/* fonts */
body {
  font-family: 'Avenir';
  font-weight: 400;
}
h2,
h4 {
  font-family: 'Avenir' !important;
  font-weight: 850 !important;
}
.MuiLoadingButton-root {
  font-family: 'Avenir' !important;
  font-weight: 850;
}
/* web-panel-css */

.MuiOutlinedInput-notchedOutline {
  border-radius: 20px !important;
}
.MuiFormControl-root .MuiOutlinedInput-root {
  height: 50px;
  background: #f6f4f4;
  border-radius: 20px;
  padding-left: 29px;
  font-weight: 400 !important;
  font-family: 'Avenir';
}

.keywordSearch .MuiOutlinedInput-root {
  background: #f6f4f4;
  border-radius: 6px !important;
}

.keywordSearch fieldset {
  border-radius: 6px !important;
  border-color: #dadada !important;
}
.keywordSearch input {
  border-radius: 6px !important;
  background-color: #fff;
}
.keywordSearch input::-webkit-input-placeholder {
  font-size: 15px;
  font-family: 'Avenir';
  font-weight: 400;
}
.keywordSearch input:-ms-input-placeholder {
  font-size: 15px;
  font-family: 'Avenir';
  font-weight: 400;
}
.keywordSearch input::placeholder {
  font-size: 15px;
  font-family: 'Avenir';
  font-weight: 400;
}
.css-1ynn27f-MuiInputBase-root-MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
  border-color: rgb(255 255 255 / 32%);
}
.MuiOutlinedInput-input {
  padding: 0px 20px;
  background: #f6f4f4;
  height: 50px;
  border-radius: 20px;
  font-size: 14px;
  color: #000;
}

.MuiInputBase-inputMultiline
{
  height: unset!important;
  border-radius: unset!important;
}

.edit-form-allinput .MuiInputBase-multiline
{
  height: unset!important;
}

.render-input input {
  padding: 0px !important;
}

.MuiTypography-h4 {
  margin-bottom: 0px;
  color: #000000;
  font-size: 36px;
}
.css-1qe96v1-Stack-root {
  margin-bottom: 43px;
}
.css-dsa49w-MuiInputBase-root-MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline,
.css-xqgjb9-MuiInputBase-root-MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
  border-color: rgb(255 255 255 / 0%);
}

.MuiFormControlLabel-label {
  color: #979797;
  text-transform: capitalize;
  font-weight: 400;
  font-size: 14px;
}
.MuiLink-underlineAlways {
  color: #979797;
  text-decoration: none;
  font-weight: 400;
  font-family: 'Avenir';
  font-size: 14px;
}
.MuiTypography-alignCenter a {
  color: #0c0cfa;
  font-weight: 850;
  font-size: 14px;
}

.MuiTypography-body2 {
  font-size: 14px;
  color: #c4c4c4;
}

.sidelogin-img img {
  height: 100%;
}

.piver-heading {
  font-size: 18px;
  font-weight: 400 !important;
  line-height: 44px;
  color: #000;
}
.piver-heading-arrow {
  margin-top: 18px !important;
}

/* web-panel-css */

.MuiListItemButton-root {
  margin: 5px 15px;
  border-radius: 21px;
  height: 40px;
  color: #7e8795;
  font-size: 14px;
  font-weight: 400 !important;
  font-family: 'Avenir';
  padding: 0px 0px 0px 18px;
}
.MuiListItemButton-root.active {
  background-color: rgb(205 205 207 / 30%);
  color: #969ca6;
}
.MuiListItemButton-root::before {
  display: none;
}
.CustomizedTableCell .Mui-active,
.CustomizedTableCell .MuiTableSortLabel-icon {
  color: #fff !important;
}

.CustomizedTableCell .MuiTableSortLabel-root:hover,
.CustomizedTableCell .MuiTableSortLabel-icon:hover {
  color: #fff !important;
}

.CustomizedTableCell .MuiTableSortLabel-root:focus {
  color: #fff !important;
}

/* .MuiPaper-elevation {
  color: #fff;
} */

.stackstyleheading {
  color: #fff;
  font-family: 'Avenir';
}
.search-list-dash {
  padding: 0px;
}
.MuiTableCell-head {
  background: #6a6a6a;
  font-family: 'Avenir';
  font-weight: 400;
  font-size: 14px;
  color: #fff;
}

.MuiTableCell-body,
.MuiTableCell-body h6 {
  font-size: 14px;
  color: #555555;
  font-family: 'Avenir' !important;
  font-weight: 500 !important;
}
.MuiTableRow-hover:nth-child(even) {
  background-color: #f4f4f4;
}

.profile-input-form .MuiOutlinedInput-input {
  height: auto;
}
.form-multioption .MuiOutlinedInput-root {
  min-height: 59px;
  height: auto !important;
  padding: 10px;
}
.edit-form-allinput .MuiOutlinedInput-input {
  padding-left: 0px;
}
.edit-form-allinput .MuiFormControl-root .MuiOutlinedInput-root {
  height: 59px;
}
.edit-modal-form .MuiOutlinedInput-root {
  padding-left: 2px;
}

.table-style2 .drawerHead::-webkit-scrollbar {
  width: 4px;
}

/* Track */
.table-style2 .drawerHead::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

/* Handle */
.table-style2 .drawerHead::-webkit-scrollbar-thumb {
  background: #000;
  border-radius: 10px;
}

/* Handle on hover */
.table-style2 .drawerHead::-webkit-scrollbar-thumb:hover {
  background: #083dfa;
}

.orderhistorydata .MuiTypography-body2 {
  color: #000 !important;
}
.edit-form-allinput .MuiOutlinedInput-notchedOutline {
  border: none;
}
.view-more-btn:hover {
  background: #6a6a6a;
}
.draw-title .MuiTypography-h4 {
  color: #fff;
}
input:-webkit-autofill {
  /* -webkit-text-fill-color: #fff !important; */
  -webkit-background-color: #f6f4f4 !important;
}

.table-style2 .drawerHead {
  max-height: 650px !important;
}

.MuiMenuItem-root {
  font-weight: 400 !important;
  font-family: 'Avenir';
}
.css-xqgjb9-MuiInputBase-root-MuiOutlinedInput-root {
  font-weight: 400 !important;
  font-family: 'Avenir';
}
@media (max-width: 1199.98px) {
  .mobile-container {
    padding: 0px 15px;
  }
  .css-1ld3b9g-MuiGrid-root > .MuiGrid-item {
    padding-top: 25px;
  }
}

@media (min-width: 900px) and (max-width: 991.98px) {
  .sidelogin-img,
  .content-login-style {
    width: 50%;
  }
}

@media (max-width: 899px) {
  .sidelogin-img,
  .content-login-style {
    width: 100%;
  }
}

@media (max-width: 575px) {
  .content-login-style {
    padding: 0px 20px;
  }
  .css-1qe96v1-Stack-root {
    margin-bottom: 25px;
  }
  .MuiTypography-h4 {
    font-size: 22px;
  }
}

.text-blue-color {
  color: #0c0cfa !important;
  font-weight: bold !important;
}

.css-1q7nlgr-MuiToggleButtonGroup-root .MuiToggleButtonGroup-grouped{
  width:calc(100% / 7)
}

.modal-content {
    width: auto
}

.fix_wd {
    width: 150px;
    margin-left: 10px;
}

.modal-title {
    width: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    padding:5px
}
.mx-5{
    margin: 0 5px;
}
.my-5{
    margin: 5px 0;
}
.mx-10{
    margin: 0 10px;
}
.my-10{
    margin: 10px 0;
}
.mx-20{
    margin: 0 20px;
}
.my-20{
    margin: 20px 0;
}
.px-5{
    padding: 0 5px;
}
.py-5{
    padding: 5px 0;
}
.px-10{
    padding: 0 10px;
}
.py-10{
    padding: 10px 0;
}
.px-20{
    padding: 0 20px;
}
.py-20{
    padding: 20px 0;
}

.ml-5 {
    margin-left: 5px;
}
.pac-container{
    z-index:9999999
}

.item_review_action {
    margin: 0;
    padding-right: 0;
}

.items_page .list_inner {
    min-height: 120px;
}
.map-marker {
    position: absolute;
    top: 44%;
    left: 50%;
    -webkit-transform: translateX(-50%) translateY(-50%);
            transform: translateX(-50%) translateY(-50%);
}
.map_address {
    display: -webkit-flex;
    display: flex; 
    -webkit-align-items: center; 
            align-items: center; 
    padding: 10px
}
.suggestion-list {
    background: #fff;
    position: absolute;
    left: 0;
    right: 0;
    border-radius: 0 0 15px 15px;   
} 

.suggestion-list * {
    background: transparent;
}

.suggestion-list a {
    padding: 5px 15px;
    border-bottom: 1px solid #eee;
    width:100%;
    display:inline-block;
    font-size:13px;
    color:#333;
}
  

.map-overlay {
    position: absolute!important;
    z-index: 3;
    left:0;
    right: 0;
    padding: 8px 0px;
    position: relative;
    max-width:calc(100% - 236px);
    margin: 3px 36px 0 200px;
}

.map-overlay input {
    border: 0px;
    border-radius: 8px;
}

.map-search {
    padding-left: 5px; 
    display: -webkit-flex; 
    display: flex; 
    -webkit-align-items: center; 
            align-items: center; 
    background: #FFF; 
    border-radius: 8px;
}

.small-grey-icon {
    color: grey;
    margin: 10px;
}
.App {
  font-family: sans-serif;
  text-align: center;
  height: 100vh;
}

.App-map {
  height: 100%;
  width: 100%;
}
.popover-button:hover {
  color: #0c0cfa;
  border-color: #0c0cfa;
}
 

.table-style2 .drawerHead {
    width: 0;
    transition: all .3s;
    -weki-transition: all .3s;
    float: right;
    margin: 0 10px;
    /* transform: translateX(110%); */
    background: #eee;
    max-height: 400px;
    overflow-y: auto;
}

.table-style2 .drawerHead .MuiContainer-root {
    padding: 0;
}

.table-style2 .drawerHead .MuiList-root.MuiList-padding {
    max-width: 100%;
    border: 1px solid #ddd;
    background: transparent;
}

.table-style2 .drawerHead .MuiList-root.MuiList-padding {
    max-width: 100%;
    background: #ffffff;
    padding: 10px 20px;
}

.table-style2 .table-content {
    float: left;
    width: 100%;
    transition: all .3s;
    -webkit-transition: all .3s;
}

 

.smallTable  .table-content {
    width: calc(100% - 500px);
}

 

.table-style2 .ViewDrawer {
    -webkit-transform: translateX(0%);
            transform: translateX(0%);
    width: 450px;
}
.smallTable .table-content .MuiTableContainer-root {
    min-width: 100%;
}


.table-style2 .css-qerafn-MuiTablePagination-root {
    float: right;   
    width:100%;
}
.smallTable  .css-qerafn-MuiTablePagination-root {
    float: left!important;
    width: calc(100% - 500px);
}
  

.draw-title {background: #083dfa;padding: 15px;color: #fff;}

.draw-title h4 {
    margin: 0;
}
