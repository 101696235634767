/* fonts */

/* sf-pro */

/* bold */

@font-face {
  font-family: 'SFProText';
  src: url('./assets/fonts/SFProText-Semibold.woff2') format('woff2'),
    url('./assets/fonts/SFProText-Semibold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

/* bold */

/* regular */
@font-face {
  font-family: 'SFProText';
  src: url('./assets/fonts/SFProText-Regular.woff2') format('woff2'),
    url('./assets/fonts/SFProText-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

/* regular */

/* sf-pro */

/* heavy-font */

@font-face {
  font-family: 'Avenir';
  src: url('./assets/fonts/Avenir-Heavy.eot');
  src: url('./assets/fonts/Avenir-Heavy.eot?#iefix') format('embedded-opentype'),
    url('./assets/fonts/Avenir-Heavy.woff2') format('woff2'),
    url('./assets/fonts/Avenir-Heavy.woff') format('woff'),
    url('./assets/fonts/Avenir-Heavy.svg#Avenir-Heavy') format('svg');
  font-weight: 850;
  font-style: normal;
  font-stretch: normal;
  unicode-range: U+0020-00FE;
}

/* heavy-font */

/* light-font */

@font-face {
  font-family: 'Avenir';
  src: url('./assets/fonts/Avenir-Light.eot');
  src: url('./assets/fonts/Avenir-Light.eot?#iefix') format('embedded-opentype'),
    url('./assets/fonts/Avenir-Light.woff2') format('woff2'),
    url('./assets/fonts/Avenir-Light.woff') format('woff'),
    url('./assets/fonts/Avenir-Light.svg#Avenir-Light') format('svg');
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  unicode-range: U+0020-00FE;
}

/* light-font */

/* medium-font */

@font-face {
  font-family: 'Avenir';
  src: url('./assets/fonts/Avenir-Medium.eot');
  src: url('./assets/fonts/Avenir-Medium.eot?#iefix') format('embedded-opentype'),
    url('./assets/fonts/Avenir-Medium.woff2') format('woff2'),
    url('./assets/fonts/Avenir-Medium.woff') format('woff'),
    url('./assets/fonts/Avenir-Medium.svg#Avenir-Medium') format('svg');
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  unicode-range: U+0020-00FE;
}

/* medium-font */

/* roman-font */

@font-face {
  font-family: 'Avenir';
  src: url('./assets/fonts/n.eot');
  src: url('./assets/fonts/n.eot?#iefix') format('embedded-opentype'),
    url('./assets/fonts/n.woff2') format('woff2'), url('./assets/fonts/n.woff') format('woff'),
    url('./assets/fonts/n.svg#n') format('svg');
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
  unicode-range: U+0020-E305;
}

/* roman-font */

/* fonts */
body {
  font-family: 'Avenir';
  font-weight: 400;
}
h2,
h4 {
  font-family: 'Avenir' !important;
  font-weight: 850 !important;
}
.MuiLoadingButton-root {
  font-family: 'Avenir' !important;
  font-weight: 850;
}
/* web-panel-css */

.MuiOutlinedInput-notchedOutline {
  border-radius: 20px !important;
}
.MuiFormControl-root .MuiOutlinedInput-root {
  height: 50px;
  background: #f6f4f4;
  border-radius: 20px;
  padding-left: 29px;
  font-weight: 400 !important;
  font-family: 'Avenir';
}

.keywordSearch .MuiOutlinedInput-root {
  background: #f6f4f4;
  border-radius: 6px !important;
}

.keywordSearch fieldset {
  border-radius: 6px !important;
  border-color: #dadada !important;
}
.keywordSearch input {
  border-radius: 6px !important;
  background-color: #fff;
}
.keywordSearch input::placeholder {
  font-size: 15px;
  font-family: 'Avenir';
  font-weight: 400;
}
.css-1ynn27f-MuiInputBase-root-MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
  border-color: rgb(255 255 255 / 32%);
}
.MuiOutlinedInput-input {
  padding: 0px 20px;
  background: #f6f4f4;
  height: 50px;
  border-radius: 20px;
  font-size: 14px;
  color: #000;
}

.MuiInputBase-inputMultiline
{
  height: unset!important;
  border-radius: unset!important;
}

.edit-form-allinput .MuiInputBase-multiline
{
  height: unset!important;
}

.render-input input {
  padding: 0px !important;
}

.MuiTypography-h4 {
  margin-bottom: 0px;
  color: #000000;
  font-size: 36px;
}
.css-1qe96v1-Stack-root {
  margin-bottom: 43px;
}
.css-dsa49w-MuiInputBase-root-MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline,
.css-xqgjb9-MuiInputBase-root-MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
  border-color: rgb(255 255 255 / 0%);
}

.MuiFormControlLabel-label {
  color: #979797;
  text-transform: capitalize;
  font-weight: 400;
  font-size: 14px;
}
.MuiLink-underlineAlways {
  color: #979797;
  text-decoration: none;
  font-weight: 400;
  font-family: 'Avenir';
  font-size: 14px;
}
.MuiTypography-alignCenter a {
  color: #0c0cfa;
  font-weight: 850;
  font-size: 14px;
}

.MuiTypography-body2 {
  font-size: 14px;
  color: #c4c4c4;
}

.sidelogin-img img {
  height: 100%;
}

.piver-heading {
  font-size: 18px;
  font-weight: 400 !important;
  line-height: 44px;
  color: #000;
}
.piver-heading-arrow {
  margin-top: 18px !important;
}

/* web-panel-css */

.MuiListItemButton-root {
  margin: 5px 15px;
  border-radius: 21px;
  height: 40px;
  color: #7e8795;
  font-size: 14px;
  font-weight: 400 !important;
  font-family: 'Avenir';
  padding: 0px 0px 0px 18px;
}
.MuiListItemButton-root.active {
  background-color: rgb(205 205 207 / 30%);
  color: #969ca6;
}
.MuiListItemButton-root::before {
  display: none;
}
.CustomizedTableCell .Mui-active,
.CustomizedTableCell .MuiTableSortLabel-icon {
  color: #fff !important;
}

.CustomizedTableCell .MuiTableSortLabel-root:hover,
.CustomizedTableCell .MuiTableSortLabel-icon:hover {
  color: #fff !important;
}

.CustomizedTableCell .MuiTableSortLabel-root:focus {
  color: #fff !important;
}

/* .MuiPaper-elevation {
  color: #fff;
} */

.stackstyleheading {
  color: #fff;
  font-family: 'Avenir';
}
.search-list-dash {
  padding: 0px;
}
.MuiTableCell-head {
  background: #6a6a6a;
  font-family: 'Avenir';
  font-weight: 400;
  font-size: 14px;
  color: #fff;
}

.MuiTableCell-body,
.MuiTableCell-body h6 {
  font-size: 14px;
  color: #555555;
  font-family: 'Avenir' !important;
  font-weight: 500 !important;
}
.MuiTableRow-hover:nth-child(even) {
  background-color: #f4f4f4;
}

.profile-input-form .MuiOutlinedInput-input {
  height: auto;
}
.form-multioption .MuiOutlinedInput-root {
  min-height: 59px;
  height: auto !important;
  padding: 10px;
}
.edit-form-allinput .MuiOutlinedInput-input {
  padding-left: 0px;
}
.edit-form-allinput .MuiFormControl-root .MuiOutlinedInput-root {
  height: 59px;
}
.edit-modal-form .MuiOutlinedInput-root {
  padding-left: 2px;
}

.table-style2 .drawerHead::-webkit-scrollbar {
  width: 4px;
}

/* Track */
.table-style2 .drawerHead::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

/* Handle */
.table-style2 .drawerHead::-webkit-scrollbar-thumb {
  background: #000;
  border-radius: 10px;
}

/* Handle on hover */
.table-style2 .drawerHead::-webkit-scrollbar-thumb:hover {
  background: #083dfa;
}

.orderhistorydata .MuiTypography-body2 {
  color: #000 !important;
}
.edit-form-allinput .MuiOutlinedInput-notchedOutline {
  border: none;
}
.view-more-btn:hover {
  background: #6a6a6a;
}
.draw-title .MuiTypography-h4 {
  color: #fff;
}
input:-webkit-autofill {
  /* -webkit-text-fill-color: #fff !important; */
  -webkit-background-color: #f6f4f4 !important;
}

.table-style2 .drawerHead {
  max-height: 650px !important;
}

.MuiMenuItem-root {
  font-weight: 400 !important;
  font-family: 'Avenir';
}
.css-xqgjb9-MuiInputBase-root-MuiOutlinedInput-root {
  font-weight: 400 !important;
  font-family: 'Avenir';
}
@media (max-width: 1199.98px) {
  .mobile-container {
    padding: 0px 15px;
  }
  .css-1ld3b9g-MuiGrid-root > .MuiGrid-item {
    padding-top: 25px;
  }
}

@media (min-width: 900px) and (max-width: 991.98px) {
  .sidelogin-img,
  .content-login-style {
    width: 50%;
  }
}

@media (max-width: 899px) {
  .sidelogin-img,
  .content-login-style {
    width: 100%;
  }
}

@media (max-width: 575px) {
  .content-login-style {
    padding: 0px 20px;
  }
  .css-1qe96v1-Stack-root {
    margin-bottom: 25px;
  }
  .MuiTypography-h4 {
    font-size: 22px;
  }
}

.text-blue-color {
  color: #0c0cfa !important;
  font-weight: bold !important;
}

.css-1q7nlgr-MuiToggleButtonGroup-root .MuiToggleButtonGroup-grouped{
  width:calc(100% / 7)
}
