.App {
  font-family: sans-serif;
  text-align: center;
  height: 100vh;
}

.App-map {
  height: 100%;
  width: 100%;
}
.popover-button:hover {
  color: #0c0cfa;
  border-color: #0c0cfa;
}